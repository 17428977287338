import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '#lib/utils.js'

import { Icon, type IconName } from './Icon'
import { Spinner } from './Spinner'

const buttonVariants = cva(
  'ring-offset-background focus-visible:ring-ring inline-flex items-center justify-center gap-3 whitespace-nowrap rounded-md text-sm font-semibold transition-colors focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-brand text-primary-foreground hover:bg-brand-hover shadow-md',
        destructive:
          'bg-destructive-600 text-white hover:bg-destructive-700 shadow-md',
        outline:
          'text-current bg-transparent border border-border font-medium hover:bg-current/10 [&>[data-slot="icon"]]:opacity-50',
        secondary:
          'bg-background text-secondary-foreground hover:bg-background/80 border border-border',
        ghost: 'hover:bg-secondary font-medium',
        link: 'text-primary underline-offset-4 hover:underline font-medium',
        brand: 'bg-brand text-primary-foreground hover:bg-brand/80 size',
      },
      size: {
        default: 'h-8 px-4 [&>[data-slot="icon"]]:size-3.5',
        xs: 'h-6 px-2 text-xs [&>[data-slot="icon"]]:size-2.5 gap-1.5',
        sm: 'h-7 rounded-md px-3 [&>[data-slot="icon"]]:size-3 gap-2',
        lg: 'h-10 rounded-md px-8 [&>[data-slot="icon"]]:size-4',
        icon: 'p-0 aspect-square [&>[data-slot="icon"]]:size-7/12 flex',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export type ButtonStyles = VariantProps<typeof buttonVariants>

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonStyles {
  asChild?: boolean
  loading?: boolean
  icon?: IconName
}

const Button = ({
  className,
  variant,
  size,
  asChild = false,
  icon,
  loading = false,
  children,
  type = 'button',
  ...props
}: ButtonProps) => {
  const Comp = asChild ? Slot : 'button'

  return (
    <Comp
      className={cn(buttonVariants({ variant, size, className }))}
      type={type}
      {...props}
      disabled={props.disabled || loading}
    >
      {loading ? <Spinner /> : icon ? <Icon name={icon} /> : null}
      {children}
    </Comp>
  )
}

export { Button, buttonVariants }
